<template>
  <div class="_wrap">
    <div class="_banner">
      <img
        class="_bimg"
        :src="$IMG_URL + $store.state.webData.top_image.visit_list_image"
        alt="八路军驻洛办事处纪念馆-官方网站"
      />
      <img
        class="_bgImg"
        src="@/assets/image/visit-img/visit_bg_banner.png"
        alt="八路军驻洛办事处纪念馆-官方网站"
      />
    </div>

    <div class="_content">
      <div class="_auto">
        <div class="_kfsj" id="kfsj">
          <h4>
            <span>开放时间</span>
            <img
              class="_simg"
              src="@/assets/image/visit-img/visit_title_s.png"
              alt="八路军驻洛办事处纪念馆-官方网站"
            />
            <i class="_xian"></i>
          </h4>
          <p class="_zr">每周二至周日</p>
          <div class="_time">
            <div>
              <p class="_impact1">
                {{ $store.state.webData.other_data.open_time }}
              </p>
              <span>开馆时间</span>
            </div>
            <i>
              <img
                src="../../assets/image/visit-img/visit_bg_time.png"
                alt="八路军驻洛办事处纪念馆-官方网站"
              />
            </i>
            <div>
              <p class="_impact1">
                {{ $store.state.webData.other_data.stop_time }}
              </p>
              <span>停止入场时间</span>
            </div>
            <i>
              <img
                src="../../assets/image/visit-img/visit_bg_time.png"
                alt="八路军驻洛办事处纪念馆-官方网站"
              />
            </i>
            <div>
              <p class="_impact1">
                {{ $store.state.webData.other_data.close_time }}
              </p>
              <span>闭馆时间</span>
            </div>
          </div>

          <div class="_tips">
            八路军驻洛办事处纪念馆
            每周二至周日开放参观，周一全天闭馆（国家法定节假日除外）
          </div>
        </div>

        <div class="_kfsj _cgyy" id="cgyy">
          <h4>
            <span>参观</span>
            <img
              class="_simg"
              src="@/assets/image/visit-img/visit_title_n.png"
              alt="八路军驻洛办事处纪念馆-官方网站"
            />
            <i class="_xian"></i>
          </h4>

          <div class="_fs">
            <div class="_h4">预约方式</div>
            <div class="_yyHtml" v-html="vistData.visit_appoint_type"></div>
          </div>

          <div class="_fs _lc">
            <div class="_h4">参观方式</div>
            <div class="_lcont">
              <div class="_lcHtml" v-html="vistData.visit_appoint_flow"></div>
              <!-- <p>1.关注“洛八办”微信公众号</p>
              <p>2.关注“洛八办”微信公众号</p>
              <p>3.关注“洛八办”微信公众号</p>
              <p>4.关注“洛八办”微信公众号</p> -->
            </div>
          </div>
        </div>

        <div class="_bg_map">
          <img
            style="width: 230px; height: 230px"
            :src="$IMG_URL + vistData.oa_qrcode"
            alt="八路军驻洛办事处纪念馆-官方网站"
          />
          <p>扫码关注官方微信公众号</p>
        </div>
      </div>

      <div class="_bigBg">
        <div class="_auto">
          <div class="_kfsj _ztfb" id="ztfb">
            <h4>
              <span>展厅分布</span>
              <img
                class="_simg"
                src="@/assets/image/visit-img/visit_title_n.png"
                alt="八路军驻洛办事处纪念馆-官方网站"
              />
              <i class="_xian"></i>
            </h4>

            <div class="_zt_img">
              <img
                :src="$IMG_URL + vistData.exhibition"
                alt="八路军驻洛办事处纪念馆-官方网站"
              />
            </div>
          </div>
          <div class="_kfsj _jtlx" id="jtlx">
            <h4>
              <span>交通路线</span>
              <img
                class="_simg"
                src="@/assets/image/visit-img/visit_title_n.png"
                alt="八路军驻洛办事处纪念馆-官方网站"
              />
              <i class="_xian"></i>
            </h4>

            <div class="_jtlx_cont">
              <div class="_left">
                <div id="map"></div>
              </div>
              <div class="_right">
                <div class="_r_t">
                  <div class="_r_t_l">
                    <h4>公交</h4>
                    <img
                      src="@/assets/image/visit-img/visit_bg_traffic_bus.png"
                      alt="八路军驻洛办事处纪念馆-官方网站"
                    />
                  </div>

                  <div class="_xl _impact">
                    {{ vistData.transit }}
                  </div>
                </div>

                <div class="_r_b _r_t">
                  <div class="_r_t_l">
                    <h4>自驾</h4>
                    <img
                      src="@/assets/image/visit-img/visit_bg_traffic_car.png"
                      alt="八路军驻洛办事处纪念馆-官方网站"
                    />
                  </div>
                  <div class="_xl xl1">
                    <!-- {{ vistData.self_driving_qrcode }} -->
                    <img
                      class="_dh_img"
                      :src="$IMG_URL + vistData.self_driving_qrcode"
                      alt="八路军驻洛办事处纪念馆-官方网站"
                    />
                    <p>微信扫码一键导航</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="_kfsj _jjfw" id="jjfw">
            <h4>
              <span>讲解服务</span>
              <img
                class="_simg"
                src="@/assets/image/visit-img/visit_title_n.png"
                alt="八路军驻洛办事处纪念馆-官方网站"
              />
              <i class="_xian"></i>
            </h4>
            <div class="_jjfw_cont">
              <div class="_jjfw_left">
                <div class="_jjfw_lcont">
                  <!-- <h4>使用方法</h4> -->
                  <div class="jjHtml" v-html="vistData.guide_service"></div>
                </div>
              </div>

              <div class="_right">
                <img
                  :src="$IMG_URL + vistData.guide_service_qrcode"
                  alt="八路军驻洛办事处纪念馆-官方网站"
                />
              </div>
            </div>
          </div>

          <div class="_kfsj _ylxz" id="ylxz">
            <h4>
              <span>游览须知</span>
              <img
                class="_simg"
                src="@/assets/image/visit-img/visit_title_n.png"
                alt="八路军驻洛办事处纪念馆-官方网站"
              />
              <i class="_xian"></i>
            </h4>

            <div class="_cont" v-html="vistData.travel_notice"></div>
          </div>

          <div class="_kfsj _bmfw" id="bmfw">
            <h4>
              <span>便民服务</span>
              <img
                class="_simg"
                src="@/assets/image/visit-img/visit_title_n.png"
                alt="八路军驻洛办事处纪念馆-官方网站"
              />
              <i class="_xian"></i>
            </h4>

            <div class="_cont" v-html="vistData.convenient_services"></div>
          </div>
        </div>

        <div class="_bm_bg">
          <img
            src="../../assets/image/visit-img/visit_bg_2.png"
            alt="八路军驻洛办事处纪念馆-官方网站"
          />
        </div>
      </div>
    </div>

    <mainFooter ref="mainFooterRef"></mainFooter>
  </div>
</template>

<script>
import { getVisit } from "@/api/getData";
export default {
  data() {
    return {
      wbTitle: "八路军驻洛办事处纪念馆-参观",
      keywords: "八路军驻洛办事处纪念馆,洛八办",
      desc: "八路军驻洛办事处纪念馆",
      title: "",
      obj: {},
      vistData: {},
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: this.wbTitle,
      // meta: [
      //   { name: "keywords", content: this.keywords },
      //   { name: "description", content: this.desc },
      // ]
    };
  },
  async mounted() {
    await this.getData();

    this.createMap();
    this.jumpId();
  },
  created() {},

  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      console.log(to);
      let title = to.query.tit;
      this.title = title;
      for (let i in this.obj) {
        console.log(i);
        if (this.title == i) {
          this.$scrollToSection(this.obj[i], 130);
        }
      }
    },
  },
  methods: {
    jumpId() {
      const title = this.$route.query.tit;

      console.log(title);
      const obj = {
        开放时间: "kfsj",
        参观预约: "cgyy",
        展厅分布: "ztfb",
        交通路线: "jtlx",
        讲解服务: "jjfw",
        游览须知: "ylxz",
        便民服务: "bmfw",
      };
      this.obj = obj;
      if (title) {
        for (let i in obj) {
          // console.log(i)
          if (title == i) {
            this.$scrollToSection(obj[i], 130);
          }
        }
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // 平滑滚动
        });
      }
    },
    async getData() {
      await getVisit().then((res) => {
        console.log(res);
        this.vistData = res.data;
      });
    },
    createMap() {
      var map = new BMap.Map("map"); // 创建Map实例
      map.centerAndZoom(new BMap.Point(112.487944, 34.682779), 17); // 初始化地图,设置中心点坐标和地图级别
      map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放

      var marker = new BMap.Marker(new BMap.Point(112.487944, 34.682779));
      // 在地图上添加点标记
      map.addOverlay(marker);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

@mixin ba_cont {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #831313;
  transform: rotate(45deg);
}

._wrap {
  width: 100%;
  overflow: hidden;
  background-color: #fdfaf6;
}

._yyHtml {
  position: relative;
  z-index: 9;
}
._banner {
  width: 100%;
  height: 700px;
  position: relative;

  ._bimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ._bgImg {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

._content {
  width: 100%;
  background-color: #e8e0d2;

  ._auto {
    width: 75%;
    margin: 0 auto;
    position: relative;

    ._fs {
      padding: 46px 0 28px;
      border-bottom: 1px dotted #d7c6a8;

      ._h4 {
        color: #333;
        font-size: 24px;
        position: relative;
      }

      ._h4::after {
        @include ba_cont;
        margin-left: 20px;
        position: absolute;
        top: 13px;
      }

      p {
        font-size: 20px;
        color: #333;
        letter-spacing: 1px;
        margin-top: 15px;
        width: 44%;
      }
    }

    ._lc {
      border-bottom: none;
      padding: 22px 0 18px;

      ._lcont {
        p {
          color: #333;
          font-size: 20px;
          line-height: 25px;
        }
      }
    }

    ._bg_map {
      position: absolute;
      right: -230px;
      bottom: -110px;
      width: 70%;
      height: 1025px;
      background-image: url("../../assets//image//visit-img/visit_bg_map.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      text-align: center;

      img {
        margin-top: 61%;
        // transform: translateY(-115px);
      }

      p {
        margin: 12px 0;
      }
    }
  }

  ._kfsj {
    border-left: 1px solid #d7c6a8;
    padding: 60px;
    // overflow: hidden;

    h4 {
      position: relative;

      span {
        font-size: 36px;
        color: #333;
        font-weight: bold;
      }

      ._simg {
        position: absolute;
        left: -73px;
        top: 10px;
      }

      ._xian {
        display: inline-block;
        width: 110%;
        position: absolute;
        height: 1px;
        top: 23px;
        left: 195px;
        border-top: 1px solid #d7c6a8;
        z-index: 1;
      }
    }

    ._zr {
      font-size: 20px;
      margin: 40px 0 25px;
    }

    ._time {
      display: flex;
      // align-items: center;
      align-items: flex-start;

      > div {
        text-align: center;
        margin-bottom: 25px;
      }

      p {
        font-size: 72px;

        color: #9b5454;
        letter-spacing: 2px;
      }

      span {
        color: #722c20;
        font-size: 20px;
        letter-spacing: 1px;
      }

      i {
        font-style: normal;
        margin: 0 20px;
        font-size: 50px;
      }
    }

    ._tips {
      font-size: 20px;
      color: #333;
    }
  }

  ._bigBg {
    background-image: url("../../assets/image/visit-img/visit_bg_1.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;

    ._zt_img {
      width: 100%;
      height: 757px;
      background: #fff8ed;
      text-align: center;
      margin-top: 40px;

      img {
        width: 97.2%;
        height: 717px;
        margin-top: 20px;
        object-fit: cover;
      }
    }

    ._jtlx {
      ._jtlx_cont {
        margin-top: 40px;
        display: flex;

        ._left {
          width: 50%;
          height: 540px;

          #map {
            width: 100%;
            height: 100%;
            background-color: red;
          }
        }

        ._right {
          width: 50%;
          height: 540px;

          ._r_t {
            display: flex;
            height: 50%;

            ._r_t_l {
              height: 100%;
              width: 83px;
              text-align: center;
              background-color: #92723b;

              h4 {
                font-size: 36px;
                width: 36px;
                letter-spacing: 1px;
                color: white;
                margin: 0 auto;
                margin-top: 90%;
                margin-bottom: 3px;
              }

              img {
                width: 60px;
              }
            }

            ._xl {
              flex: 1;
              color: white;
              font-size: 24px;
              padding: 84px 55px 82px 58px;
              line-height: 35px;
              background-image: url("../../assets/image/visit-img/visit_bg_traffic.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              border-bottom: 1px solid #bea984;
              box-sizing: border-box;
              border-top-right-radius: 10px;
            }

            .xl1 {
              border: none;
              border-top-right-radius: 0;
              border-bottom-right-radius: 10px;
              padding: 0;
              text-align: center;

              ._dh_img {
                width: 200px;
                height: 200px;
                margin-top: 20px;
              }

              p {
              }
            }
          }

          ._r_b {
            ._r_t_l {
              background-color: #9f5a09;
            }
          }
        }
      }
    }

    ._jjfw {
      ._jjfw_cont {
        display: flex;
        margin-top: 40px;

        ._jjfw_left {
          width: 70%;
          height: 375px;
          background-image: url("../../assets/image/visit-img/visit_bg_explanation.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-color: #fff;

          ._jjfw_lcont {
            width: 100%;
            height: 100%;
            padding: 100px 130px 55px 55px;
          }

          h4 {
            color: #333;
            font-size: 30px;
            margin-bottom: 64px;
            display: flex;
            align-items: center;
          }

          h4::after {
            @include ba_cont;
            margin-left: 10px;
          }

          p {
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 1px;
          }
        }

        ._right {
          width: 30%;
          height: 375px;
          // background-color: red;
          background-image: url("../../assets/image/visit-img/visit_bg_explanation code.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          text-align: center;

          img {
            width: 230px;
            height: 230px;
            text-align: center;
            margin-top: 17.5%;
          }
        }
      }
    }

    ._ylxz {
      ._cont {
        margin-top: 40px;
      }
    }

    ._bmfw {
      padding-bottom: 130px;

      ._cont {
        margin-top: 40px;
      }
    }
  }
}

._bm_bg {
  // width: 100%;
  // height: 550px;
  // background-image: url("../../assets/image/visit-img/visit_bg_2.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  position: absolute;
  bottom: -175px;
  right: 0;
}

::v-deep {
  .BMap_cpyCtrl {
    display: none;
  }

  .anchorBL {
    display: none;
  }
}

@include respondTo("phone") {
  ._banner {
    height: 300px;
  }
  ._auto {
    padding: 0 8px;
  }

  ._bg_map{
    display: none;
  }

  ._content {
    ._kfsj {
      padding: 10px 0 0 10px;
      h4{
        span{
          font-size: 22px;
        }
        ._simg{
          left: -20px;
          width: 18px;
          top: 5px;
        }
        ._xian{
          width: 50%;
          left: 100px;
          top: 15px;
        }
      }

      ._zr{
        font-size: 16px;
        margin: 10px 0 10px;
      }

      ._time{
        >div{
          margin-bottom: 10px;
          p{
            font-size: 26px;
          }

          span{
            font-size: 16px;
          }
        }
        i{
          margin: 0 5px;
          font-size: 24px;
        }
      }

      ._tips{
        font-size: 16px;
      }
    }

    ._cgyy{
      margin-top:20px;
    }



    ._auto{
      ._fs{
      padding: 10px 0;
      ._h4{
        font-size: 18px;
      }
      ._h4::after{
        top: 10px;
      }
    }
    }


    ._bigBg{

      ._zt_img{
        width: 100%;
        height: 250px;
        margin-top: 20px;
        img{
          width: 100%;
          height: 100%;
          margin-top: 0;
        }
      }


      ._jtlx{
      margin-top: 20px;

      ._jtlx_cont{
          margin-top: 20px;
          display: block;
          ._left{
            width: 100%;
            height: 300px;
          }
          ._right{
            height: 320px;
            width: 100%;
            ._r_t{

              ._r_t_l{
                h4{
                  font-size: 22px;
                  margin-top: 30px;
                }
                img{
                  width: 30px;
                }
              }
              ._xl {
                padding: 0;
                font-size: 18px;
                padding-top: 20px;
              }
              .xl1{
                padding-top: 10px;
                ._dh_img{
                  width: 120px;
                  height: 120px;
                  margin-top: 0;
                }
                p{
                  line-height: 0;
                  margin-top: 5px;
                  font-size: 16px;
                }
              }
            }
            
          }
      }
    }


    ._jjfw{
      margin-top: 20px;
      ._jjfw_cont{
        margin-top: 20px;
        display: block;
        ._jjfw_left{
          width: 100%;  
          height: auto;
          ._jjfw_lcont{
            padding: 10px;
          }
        }
        ._right{
          margin-top: 10px;
          width: 100%;
          height: 270px;
          img{
            margin-top: 20px;
          }
        }
      }
    }

    ._ylxz{
      margin-top: 20px;
      ._cont{
        margin-top: 20px;
      }
    }

    ._bmfw{
      margin-top: 20px;
      padding-bottom: 50px;
      ._cont{
        margin-top: 20px;
      }
    }
    }



  }
}
</style>